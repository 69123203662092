(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('UnitsArchiveAdministrationController', UnitsArchiveAdministrationController);

    UnitsArchiveAdministrationController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$kWindow',
        '$translate',
        'kendoUtility',
        'unitService',
        'baseDataService',
        'commonUtility',
        'htmlElementService',
        'sanitizeService',
        'confirmDialogService'
    ];

    function UnitsArchiveAdministrationController(
        $q,
        $scope,
        $rootScope,
        $kWindow,
        $translate,
        kendoUtility,
        unitService,
        baseDataService,
        commonUtility,
        htmlElementService,
        sanitizeService,
        confirmDialogService
    ) {
        var langId = localStorage['NG_TRANSLATE_LANG_KEY'];
        let expandedRowIds = [];

        var unitsArchiveDataSource = new kendo.data.DataSource({
            transport: {
                read: function (e) {
                    var activeFilter = {
                        field: "IsActive",
                        operator: "contains",
                        value: "0"
                    };
                    !e.data.filter
                        ? e.data.filter = { filters: [] }
                        : e.data.filter.filters;

                    e.data.filter.filters.push(activeFilter)

                    unitService.getUnitsByContractorIdWithUnitGroup(
                        $rootScope.authData.contractorId,
                        e.data.page,
                        e.data.pageSize,
                        e.data.filter,
                        e.data.sort
                    ).then(response => {
                        $scope.units = response.items;
                        e.success({
                            data: response.items,
                            total: response.totalCount
                        });
                    });
                }
            },
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            pageSize: 25,
            schema: {
                data: "data",
                total: "total",
                model: {
                    id: 'id',
                    fields: {
                        externalUnitId: { editable: false },
                        description: { editable: false },
                        supplierName: { editable: false },
                        measurementUnitTypeId: { editable: false },
                        resourceId: { editable: false }
                    }
                },
            }
        });


        const unitsArchiveGridOptions = langId => ({
            dataSource: unitsArchiveDataSource,
            height: kendoUtility.calculateRemainingSpace(-40),
            pageable: {
                pageSize: 25,
                refresh: true,
                pageSizes: [25, 50, 100],
                buttonCount: 5,
                messages: {
                    display: $translate.instant('G_PAGING_DISPLAY'),
                    empty: $translate.instant('G_PAGING_EMPTY'),
                    page: $translate.instant('G_PAGING_PAGE'),
                    of: $translate.instant('G_PAGING_OF'),
                    itemsPerPage: $translate.instant('G_PAGING_ITEMSPERPAGE'),
                    first: $translate.instant('G_PAGING_FIRST'),
                    previous: $translate.instant('G_PAGING_PREVIOUS'),
                    next: $translate.instant('G_PAGING_NEXT'),
                    last: $translate.instant('G_PAGING_LAST'),
                    refresh: $translate.instant('G_PAGING_REFRESH')
                }
            },
            columns: [
                {
                    field: 'id',
                    hidden: true
                },
                {
                    field: 'externalUnitId',
                    title: $translate.instant('ADMIN_UNITS_PRODUCT_NUMBER'),
                    template: function (dataItem) {
                        return dataItem.externalUnitId ? sanitizeService.sanitizingStringContent(dataItem.externalUnitId) : '';
                    },
                },
                {
                    field: 'description',
                    title: $translate.instant('ADMIN_UNITS_PRODUCT_NAME'),
                    template: function (dataItem) {
                        return dataItem.description ? sanitizeService.sanitizingStringContent(dataItem.description) : '';
                    },
                },
                {
                    field: 'supplierName',
                    title: $translate.instant('ADMIN_UNITS_SUPPLIER'),
                    template: function (dataItem) {
                        const hasSupplier = dataItem.supplier !== null;
                        dataItem.supplierName = hasSupplier ? dataItem.supplier.name : '';
                        const warningIcon = !hasSupplier ?
                            '<i class="fas fa-exclamation-triangle text-warning" title="' +
                            $translate.instant('ADMIN_UNITS_MISSING_SUPPLIER') + '"></i> ' : '';
                        return warningIcon + dataItem.supplierName;
                    },
                },
                {
                    field: 'measurementUnitTypeId',
                    title: $translate.instant('ADMIN_UNITS_MEASUREMENT_UNIT'),
                    template: function (dataItem) {
                        let item = $scope.measurementTypes.find(function (measurementType) {
                            return measurementType.measurementUnitTypeId === dataItem.measurementUnitTypeId;
                        });

                        if (item) {
                            dataItem.measurementUnitType = item;
                            dataItem.measurementUnitTypeName = item.measurementUnitDescription;
                        }
                        return dataItem.measurementUnitTypeName ? dataItem.measurementUnitTypeName : '';
                    },
                    groupHeaderTemplate: $translate.instant('ADMIN_UNITS_MEASUREMENT_UNIT', null, null, langId) + ": #=items[0] && items[0].measurementUnitTypeName ? items[0].measurementUnitTypeName : '' #"
                },
                {
                    field: 'resourceId',
                    title: $translate.instant('ADMIN_UNITS_RESOURCE'),
                    template: function (dataItem) {
                        if (dataItem.resourceId) {
                            let item = $scope.resources.find(function (resource) {
                                return resource.resourceId == dataItem.resourceId;
                            });

                            if (item) {
                                dataItem.resource = item;
                                dataItem.resourceName = item.resourceDescription;
                            }
                        }

                        return dataItem.resourceName ? dataItem.resourceName : '';
                    },
                    groupHeaderTemplate: $translate.instant('ADMIN_UNITS_RESOURCE') + ": #=items[0] && items[0].resourceName ? items[0].resourceName : '' #",
                },
                {
                    command: [
                        {
                            name: 'restoreUnit',
                            template: `<a class="k-grid-restoreUnit"><i class="fas fa-undo" title="${$translate.instant('ADMIN_UNITS_RESTORE_BUTTON_TEXT')}"></i></a>`,
                            click: e => toggleUnitArchive(e),
                        },
                    ],
                    width: 100,
                }
            ],
            editable: false,
            detailInit: detailInit,
            detailTemplate: kendo.template($('#customer-unit-batch-archive-template').html()),
            detailCollapse: (e) => {
                const dataGrid = $('#units-archive-administration-grid').data('kendoGrid');
                const dataItems = dataGrid.dataSource.view();

                const dataItem = dataItems.find(x => x.uid === e.masterRow.data('uid'));

                if (dataItem) {
                    expandedRowIds = expandedRowIds.filter(x => x !== dataItem.id);
                }
            },
            dataBound: function () {
                const dataGrid = $('#units-archive-administration-grid').data('kendoGrid');
                const dataItems = dataGrid.dataSource.view();

                // reopen expanded rows on grid refresh
                $.each(expandedRowIds, function (_, v) {
                    $.each(dataItems, function (_, y) {
                        if (v === y.id) {
                            dataGrid.expandRow(dataGrid.tbody.find("tr[data-uid='" + y.uid + "']"));
                        }
                    });
                });
            },
            filterable: {
                name: "FilterMenu",
                extra: false,
                operators: {
                    string: {
                        contains: $translate.instant('G_SEARCH_OPTION_CONTAINS', null, null, langId),
                    }
                },
            },
            filterMenuInit: function (e) {
                if (e.field === "resourceName" || e.field === "supplierName") {
                    let filterMultiCheck = this.thead.find("[data-field=" + e.field + "]").data("kendoFilterMenu");
                    filterMultiCheck.container.empty();
                    filterMultiCheck.checkSource.sort({
                        field: e.field,
                        dir: "asc"
                    });
                    filterMultiCheck.checkSource.filter([]);
                    filterMultiCheck.checkSource.data(filterMultiCheck.checkSource.view().toJSON());
                    filterMultiCheck.createCheckBoxes();
                }
            },
            resizable: true,
            sortable: true,
            groupable: true,
        });

        $scope.$on('refreshProductArchiveGrid', () => refreshProductsArchiveGrid());

        function refreshProductsArchiveGrid() {
            if ($scope.unitsArchiveGridOptions) {
                $scope.unitsArchiveGridOptions.dataSource.read();
            }
        }

        $scope.unitsArchiveGridOptions = unitsArchiveGridOptions($translate.use());
        $scope.$on('langChanged', (event, code) => {
            $scope.unitsunitsArchiveGridOptions = unitsArchiveGridOptions(code);
            const grid = kendoUtility.createWidget('kendoGrid', $scope.unitsArchiveGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#units-archive-administration-grid')]);
            langId = code;
        });
        $scope.toggleUnitArchive = toggleUnitArchive;

        function toggleUnitArchive(event) {
            var unit = getUnitFromGrid(event)

            confirmDialogService.open(
                {
                    modalWidth: 400,
                    modalHeight: 150
                },
                $translate.instant("ADMIN_UNITS_RESTORE_WARNING") + `${"\n"}${unit.externalUnitId} ${unit.description}`,
                $translate.instant("ADMIN_UNITS_RESTORE_WARNING_TITLE"),
                $translate.instant("G_OK"),
                $translate.instant("G_CANCEL")
            ).then(dialogResult => {
                if (dialogResult === true) {
                    unitService.updateUnitActiveStatus(unit.id, true).then(result => {
                        openInformationDialog($translate.instant("ADMIN_UNITS_RESTORED_MESSAGE"));
                        refreshProductsArchiveGrid();
                        $rootScope.$broadcast('refreshProductsGrid');
                    });
                }
            });
        }

        function getUnitFromGrid(event) {
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $('#units-archive-administration-grid')
                .data('kendoGrid')
                .dataItem(row)
                .toJSON();
        }

        initController();

        function initController() {

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'unitsArchiveAdministrationGrid',
                destination: '#units-archive-administration-grid'
            });

            htmlElementService.checkIfElementExists("filter-group")
                .then(function (result) {
                    commonUtility.calculateAdminTabMenuWidth();
                });

            var filter = {
                filters: [{
                    field: "IsActive",
                    operator: "contains",
                    value: "0"
                }]
            };
            let requests = {};
            requests.getUnitsByContractorIdWithUnitGroup = unitService.getUnitsByContractorIdWithUnitGroup($rootScope.authData.contractorId, 1, 25, filter);
            requests.getMeasurementUnitTypesWebapi = baseDataService.getMeasurementUnitTypesWebapi()
            requests.getResourcesWebApi = baseDataService.getResourcesWebApi()
            $q.all(requests)
                .then(data => {
                    extendUnits(data.getUnitsByContractorIdWithUnitGroup.items);
                    $scope.units = data.getUnitsByContractorIdWithUnitGroup.items;

                    $scope.measurementTypes = data.getMeasurementUnitTypesWebapi;
                    $scope.resources = data.getResourcesWebApi;
                    refreshProductsArchiveGrid();
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'unitsArchiveAdministrationGrid');
                });
        }

        function extendUnits(units) {
            _.forEach(units, function (item) {
                if (item.measurementUnitType) {
                    item.measurementUnitTypeName = item.measurementUnitType.measurementUnitDescription;
                }

                if (item.resource) {
                    item.resourceName = item.resource.resourceDescription;
                }
            });
        }

        function openInformationDialog(dialogContent) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    visible: false,
                    width: 400
                },
                templateUrl: 'app/shared/popups/information-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'InformationModalController',
                resolve: {
                    content: () => { return dialogContent }
                }
            });
            windowInstance.result.then(function (response) {
                return response;
            });
        }

        function isBatchValid(batch) {
            return {
                hasCertificate: !!batch.mediaId,
                hasLifetime: !!batch.lifetimeInDays,
                hasValidLifetime: batch.lifetimeInDays > 0,
                hasCertificateDate: !!batch.certificateIssuedDate,
                isComplete: !!batch.mediaId &&
                    !!batch.lifetimeInDays &&
                    batch.lifetimeInDays > 0 &&
                    !!batch.certificateIssuedDate
            };
        }

        var unitBatchGridOptions = data => ({
            dataSource: {
                data,
                schema: {
                    model: {
                        id: 'id',
                    },
                }
            },
            noRecords: true,
            messages: {
                noRecords: $translate.instant('G_NORESULTS')
            },
            persistSelection: true,
            change: function (e) {
                var id = this.element[0].id.split("_")[0];
                if (id && this.select() && this.select().length > 0) {
                    document.getElementById(`${id}_move`).style.visibility = 'visible';
                } else {
                    document.getElementById(`${id}_move`).style.visibility = 'hidden';
                }
            },
            columns: [{
                hidden: true,
                field: 'id'
            },
            {
                field: 'batch',
                title: $translate.instant('ADMIN_UNITS_BATCH_NUMBER'),
                width: "350px"
            },
            {
                field: 'certificateIssuedDate',
                title: $translate.instant('UNIT_BATCH_CERTIFICATE_ISSUED_DATE'),
                width: "150px",
                template: function (dataItem) {
                    const warningIcon = !dataItem.certificateIssuedDate ?
                        '<i class="fas fa-exclamation-triangle text-warning" title="' +
                        $translate.instant('ADMIN_UNITS_MISSING_CERTIFICATE_DATE') + '"></i> ' : '';
                    return warningIcon + (dataItem.certificateIssuedDate ? dateUtility.formatDate(dataItem.certificateIssuedDate) : '');
                },
            },
            {
                field: 'isActive',
                title: $translate.instant('ADMIN_UNITS_ACTIVE'),
                width: "40px",
                template: function (dataItem) {
                    const checked = dataItem.isActive ? 'checked' : '';
                    return `<div class="center-content"><input type="checkbox" class="is-active-checkbox" ${checked} disabled/></div>`;
                },
            },
            {
                field: 'lifetimeInDays',
                title: $translate.instant('ADMIN_UNITS_LIFETIME_IN_DAYS'),
                width: "350px",
                template: function (dataItem) {
                    const validity = isBatchValid(dataItem);
                    const warningIcon = (!validity.hasLifetime || !validity.hasValidLifetime) ?
                        '<i class="fas fa-exclamation-triangle text-warning" title="' +
                        $translate.instant('ADMIN_UNITS_INVALID_LIFETIME') + '"></i> ' : '';
                    return warningIcon + (dataItem.lifetimeInDays || '');
                },
            },
            {
                field: 'locations',
                title: $translate.instant('ADMIN_UNITS_LOCATIONS'),
                width: "350px",
                template: function (dataItem) {
                    var locations = ''
                    _.forEach(dataItem.locations, function (location) {
                        locations = locations + location.name + ", "
                    });
                    locations = locations.substr(0, locations.length - 2)

                    return locations;
                },
            },
            ],
            dataBound: function (e) {
                var grid = e.sender;
                var rows = grid.tbody.find("tr");

                rows.each(function (index, row) {
                    var dataItem = grid.dataItem(row);
                    var validity = isBatchValid(dataItem);

                    if (!validity.isComplete) {
                        $(row).addClass('incomplete-row');
                    }
                });
            },
        });

        function detailInit(e) {
            if (!expandedRowIds.includes(e.data.id)) {
                expandedRowIds.push(e.data.id);
            }

            let requests = {};
            requests.getUnitBatches = unitService.getUnitBatches(e.data.id);
            $q.all(requests)
                .then(data => {
                    $scope.batches = data.getUnitBatches.filter(x => x.isActive === false);
                    $scope.customerUnitAgreements = $scope.batches;
                    const element = e.detailRow.find(`#${e.data.id}_grid`);
                    $scope.unitBatchGridOptions = unitBatchGridOptions($scope.batches);
                    element.kendoGrid($scope.unitBatchGridOptions);
                })
                .catch(error => console.error(error))
        }
    }
}) ();
