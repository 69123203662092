(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('EquipmentJobsModalController', EquipmentJobsModalController);

    EquipmentJobsModalController.$inject = [
        '$scope',
        '$translate',
        '$kWindow',
        'itemTreeService',
        'equipmentJobDefinitionService',
        'formTypes',
        'selectedEquipmentId',
        'dateUtility'
    ];

    function EquipmentJobsModalController(
        $scope,
        $translate,
        $kWindow,
        itemTreeService,
        equipmentJobDefinitionService,
        formTypes,
        selectedEquipmentId,
        dateUtility
    ) {
        $scope.selectedEquipment = null;
        $scope.jobDefinitions = [];
        $scope.equipmentJobDefinitions = [];
        $scope.filteredEquipmentJobDefinitions = [];
        $scope.showDeactivatedJobs = false;

        initController();

        $scope.$watch('showDeactivatedJobs', () => filterEquipmentJobDefinitions());

        $scope.addEquipmentJobDefinition = addEquipmentJobDefinition;
        $scope.editEquipmentJobDefinition = editEquipmentJobDefinition;
        $scope.changeEquipmentJobStatus = changeEquipmentJobStatus;
        $scope.openEquipmentJobDefinitionGroupModal = openEquipmentJobDefinitionGroupModal;

        $scope.equipmentJobsGridOptions = {
            sortable: true,
            filterable: true,
            pageable: false,
            scrollable: {
                virtual: true
            },
            height: 500,
            width: 'auto',
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (e) => e.success($scope.filteredEquipmentJobDefinitions)
                }
            }),
            columns: [{
                field: 'description',
                title: $translate.instant('EQUIPMENT_JOB_GRID_NAME'),
                template: '<span kendo-tooltip k-content="\'{{ dataItem.description }}\'">{{dataItem.description}}</span>',
                width: 200
            }, {
                field: 'equipmentJobType',
                title: $translate.instant('EQUIPMENT_JOB_GRID_INTERVAL_TYPE'),
                template: '<span kendo-tooltip k-content="\'{{ dataItem.equipmentJobType }}\'">{{dataItem.equipmentJobType}}</span>',
                width: 100
            },{
                field: 'equipmentJobGroup',
                title: $translate.instant('EQUIPMENT_JOB_GRID_GROUP'),
                template: '<span kendo-tooltip k-content="\'{{ dataItem.equipmentJobDefinitionGroup.name }}\'">{{dataItem.equipmentJobDefinitionGroup.name}}</span>',
                width: 100
            }, {
                field: 'logActionDescription',
                title:  $translate.instant('EQUIPMENT_JOB_GRID_LOGACTION'),
                template: '<span kendo-tooltip k-content="\'{{ dataItem.logActionDescription }}\'">{{dataItem.logActionDescription}}</span>',
                width: 100
            }, {
                field: 'validFromDisplay',
                title: $translate.instant('EQUIPMENT_JOB_GRID_VALID_FROM'),
                template: "#: validFromDisplay != null ? validFromDisplay : '' #",
                width: 90
            }, {
                field: 'validTo',
                title: $translate.instant('EQUIPMENT_JOB_GRID_VALID_TO'),
                template: "#: validToDisplay != null ? validToDisplay : '' #",
                width: 90
            },{
                field: 'lastGeneratedLogDate',
                title: $translate.instant('EQUIPMENT_JOB_GRID_LAST_LOG_DATE'),
                template: '<span kendo-tooltip k-content="\'{{ dataItem.lastLogDateDisplay }}\'">{{dataItem.lastLogDateDisplay}}</span>',
                width: 100
            }, {
                field: 'lastGeneratedLogCounterValue',
                title: $translate.instant('EQUIPMENT_JOB_GRID_LAST_LOG_COUNTER'),
                template: '<span kendo-tooltip k-content="\'{{ dataItem.lastGeneratedLogCounterValue }}\'">{{dataItem.lastGeneratedLogCounterValue}}</span>',
                width: 100
            },
            {
                field: 'equipmentJobDefinitionId',
                title: ' ',
                filterable: false,
                sortable: false,
                template: (dataItem) => {
                    let buttonText = $scope.showDeactivatedJobs && dataItem ? $translate.instant('G_ACTIVATE') : $translate.instant('G_DEACTIVATE');
                    return '<div class="center-content"><button class="k-button" ng-click="changeEquipmentJobStatus(dataItem.equipmentJobDefinitionId)">' + buttonText + '</button></div>'
                },
                width: 80
            },
            {
                field: 'equipmentJobDefinitionId',
                title: ' ',
                filterable: false,
                sortable: false,
                template: (dataItem) => {
                    if (!$scope.showDeactivatedJobs) {
                        return '<div class="center-content"><button class="k-button" ng-click="editEquipmentJobDefinition(dataItem.equipmentJobDefinitionId)">' + $translate.instant('G_EDIT') + '</button></div>'
                    } else {
                        return '<div> </div>';
                    }
                },
                width: 80
            }]
        };


        function initController() {
            itemTreeService.getItemById(selectedEquipmentId).then(item => $scope.selectedEquipment = item);

            equipmentJobDefinitionService.getEquipmentJobDefinitionsByItemId(selectedEquipmentId).then(jobDefinitions => {
                if (jobDefinitions && jobDefinitions.length) {
                    jobDefinitions.forEach(jobDefinition => extEquipmentJobDefinition(jobDefinition));
                    jobDefinitions = _.orderBy(jobDefinitions, 'description');
                    $scope.equipmentJobDefinitions = jobDefinitions;

                    filterEquipmentJobDefinitions();
                }
            });

        }

        function addEquipmentJobDefinition() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('EQUIPMENT_JOB_MODAL_TITLE_ADD'),
                    resizable: false,
                    height: 800,
                    width: 800,
                    visible: false
                },
                templateUrl: 'app/equipments/equipment-job-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'EquipmentJobDetailsModalController',
                resolve: {
                    currentFormType: () =>formTypes.add,
                    selectedEquipment: () => $scope.selectedEquipment,
                    editedJobDefinition: () => null
                }
            });

            windowInstance.result.then(() => initController());
        }

        function openEquipmentJobDefinitionGroupModal() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('EQUIPMENT_JOB_GROUP_MODAL_TITLE'),
                    resizable: false,
                    minHeight: 550,
                    width: 700,
                    visible: false
                },
                templateUrl: 'app/equipments/equipment-jobs-gorup-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'EquipmentJobsGroupModalController',
                resolve: {
                    selectedEquipmentId: () => $scope.selectedEquipment.id
                }
            });

            windowInstance.result.then(() => initController());
        }


        function editEquipmentJobDefinition(editJobDefinitionId) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('EQUIPMENT_JOB_MODAL_TITLE_EDIT'),
                    resizable: false,
                    height: 860,
                    width: 800,
                    visible: false
                },
                templateUrl: 'app/equipments/equipment-job-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'EquipmentJobDetailsModalController',
                resolve: {
                    currentFormType: () => formTypes.edit,
                    selectedEquipment: () => $scope.selectedEquipment,
                    editedJobDefinition: () => _.find($scope.equipmentJobDefinitions, { equipmentJobDefinitionId: editJobDefinitionId })
                }
            });

            windowInstance.result.then(() => initController());
        }

        function extEquipmentJobDefinition(jobDefinition) {
            jobDefinition.logActionDescription = '';
            jobDefinition.equipmentJobType = getEquipmentJobDefinitionType(jobDefinition.itemJobType);
            jobDefinition.validFromDisplay = dateUtility.formatDateWithKendo(jobDefinition.validFrom);
            jobDefinition.validToDisplay = dateUtility.formatDateWithKendo(jobDefinition.validTo);
            jobDefinition.lastLogDateDisplay = dateUtility.formatDateWithKendo(jobDefinition.lastGeneratedLogDate);

            if (jobDefinition.logAction) {
                jobDefinition.logActionDescription = jobDefinition.logAction.description;
            }
        }

        function getEquipmentJobDefinitionIntervalTypeText(intervalTypeValue) {
            switch (intervalTypeValue) {
                case 2:
                    return $translate.instant('EQUIPMENT_JOB_INTERVAL_HOURS');
                case 1:
                    return $translate.instant('EQUIPMENT_JOB_INTERVAL_DAYS');
                case 0:
                    return $translate.instant('EQUIPMENT_JOB_INTERVAL_MONTHS');
                default:
                    return '';
            }
        }

        function getEquipmentJobDefinitionType(itemJobTypeValue) {
            switch (itemJobTypeValue) {
                case 2:
                    return 'Service';
                case 1:
                    return 'Teller';
                case 0:
                    return 'Periodisk';
                default:
                    return '';
            }
        }

        function filterEquipmentJobDefinitions() {
            const today = new Date().toLocaleDateString('en-CA');
            $scope.filteredEquipmentJobDefinitions = $scope.equipmentJobDefinitions.filter(x => $scope.showDeactivatedJobs
                                                                                    ? x.validTo && new Date(x.validTo).toLocaleDateString('en-CA') <= today
                                                                                    : !x.validTo || new Date(x.validTo).toLocaleDateString('en-CA') > today
            );
            $scope.equipmentJobsGridOptions.dataSource.read();
        }

        function changeEquipmentJobStatus(id) {
            const job = $scope.filteredEquipmentJobDefinitions.find(x => x.equipmentJobDefinitionId === id);
            const today = new Date().toLocaleDateString('en-CA');
            const isActive = job && (!job.validTo || new Date(job.validTo).toLocaleDateString('en-CA') > today);

            if (!isActive) {
                reactivateEquipmentJobById(job);
            } else if (isActive) {
                const reactivatedJobDefinition = {
                    Id: job.equipmentJobDefinitionId,
                    ValidTo: null,
                    ValidFrom: null,
                    CounterIntervalStartValue: null,
                    IsActive: isActive
                };

                updateEquipmentJobDefinitionStatusById(reactivatedJobDefinition);
            }
        }

        function reactivateEquipmentJobById(job) {
            const title = $scope.showDeactivatedJobs ? 'EQUIPMENT_JOB_MODAL_TITLE_REACTIVATE' : 'EQUIPMENT_JOB_MODAL_TITLE_DEACTIVATE';

            var windowInstance= $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant(title),
                    resizable: false,
                    minHeight: 300,
                    width: 400,
                    visible: false
                },
                templateUrl: 'app/equipments/reactivate-equipment-job-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ReactivateEquipmentJobModalController',
                resolve: { job: () => job, isActive: !$scope.showDeactivatedJobs }
            });

            windowInstance.result.then(response => response.result && updateEquipmentJobDefinitionStatusById(response.data));
        }

        function updateEquipmentJobDefinitionStatusById(reactivatedJobDefinition) {
            reactivatedJobDefinition.IsActive = !$scope.showDeactivatedJobs;
            equipmentJobDefinitionService.updateEquipmentJobDefinitionStatus(reactivatedJobDefinition).then(() => initController());
        }
    }
})();
