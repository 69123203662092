(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('customerService', customerService);

    customerService.$inject = ['$q', '$http', 'serviceUrls'];

    function customerService($q, $http, serviceUrls) {
        const customersWebApiUrl = `${serviceUrls.webApiBaseUrl}/customer`;
        const customerUnitAgreementsWebApiUrl = `${serviceUrls.webApiBaseUrl}/customerUnitAgreements`;

        return {
            getCustomers: getCustomers,
            getCustomersByContractorId: getCustomersByContractorId,
            getCustomersByCustomerId: getCustomersByCustomerId,
            addCustomer: addCustomer,
            updateCustomer: updateCustomer,
            getCustomersUnitAgreements: getCustomersUnitAgreements,
            addCustomerUnitAgreement: addCustomerUnitAgreement,
            updateCustomerUnitAgreement: updateCustomerUnitAgreement,
            deleteUnitAgreement: deleteUnitAgreement,
            getCustomerUnitAgreementById: getCustomerUnitAgreementById,
            exportSelectedUnitAgreements: exportSelectedUnitAgreements,
            importCustomerPriceChangesCsv: importCustomerPriceChangesCsv
        };

        function getCustomers() {
            var deferred = $q.defer();
            var requestUrl = `${customersWebApiUrl}`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function getCustomersByContractorId(contractorId) {
            var deferred = $q.defer();
            var requestUrl = `${customersWebApiUrl}/byContractorId?contractorId=${contractorId}`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function getCustomersByCustomerId(customerId) {
            var deferred = $q.defer();
            var requestUrl = `${customersWebApiUrl}/byCustomerId?customerId=${customerId}`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function addCustomer(customer) {
            const deferred = $q.defer();
            const requestUrl = `${customersWebApiUrl}`;
            $http
                .post(requestUrl, customer)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateCustomer(customer) {
            const deferred = $q.defer();
            const requestUrl = `${customersWebApiUrl}`;
            $http
                .put(requestUrl, customer)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getCustomersUnitAgreements(customerId) {
            var deferred = $q.defer();
            var requestUrl = `${customerUnitAgreementsWebApiUrl}?customerId=${customerId}`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }


        function getCustomerUnitAgreementById(unitAgreementId) {
            var deferred = $q.defer();
            var requestUrl = `${customerUnitAgreementsWebApiUrl}/byUnitAgreementId?unitAgreementId=${unitAgreementId}`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function addCustomerUnitAgreement(customerUnitAgreement) {
            const deferred = $q.defer();
            const requestUrl = `${customerUnitAgreementsWebApiUrl}`;
            $http
                .post(requestUrl, customerUnitAgreement)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateCustomerUnitAgreement(customerUnitAgreement) {
            const deferred = $q.defer();
            const requestUrl = `${customerUnitAgreementsWebApiUrl}`;
            $http
                .put(requestUrl, customerUnitAgreement)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function deleteUnitAgreement(unitAgreementId) {
            var deferred = $q.defer();
            var requestUrl = `${customerUnitAgreementsWebApiUrl}?unitAgreementId=${unitAgreementId}`;
            $http({
                url: requestUrl,
                method: "DELETE",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function exportSelectedUnitAgreements(selectedValues) {
            const deferred = $q.defer();
            const requestUrl = `${customerUnitAgreementsWebApiUrl}/ExportAgreementPriceCSV`;
            $http({
                method: 'POST',
                url: requestUrl,
                data: selectedValues,
                responseType: 'blob',
                headers: {
                    'Accept': 'text/csv',
                    'Content-Type': 'application/json'
                }
            })
                .then(response => deferred.resolve(response))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function importCustomerPriceChangesCsv(comment, importFile) {
            const deferred = $q.defer();
            const requestUrl = `${customerUnitAgreementsWebApiUrl}/PriceChangesCSV`;

            var content = {
                reason: comment,
                file: importFile.base64Content
            }

            $http
                .post(requestUrl, content)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
