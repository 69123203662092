(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('CustomersAdministrationController', CustomersAdministrationController);

    CustomersAdministrationController.$inject = [
        '$q',
        '$scope',
        '$state',
        '$rootScope',
        '$kWindow',
        '$translate',
        'kendoUtility',
        'formTypes',
        'userService',
        'customerService',
        'dateUtility',
        'unitService',
        'commonUtility',
        'htmlElementService',
        'sanitizeService',
        'confirmDialogService'
    ];

    function CustomersAdministrationController(
        $q,
        $scope,
        $state,
        $rootScope,
        $kWindow,
        $translate,
        kendoUtility,
        formTypes,
        userService,
        customerService,
        dateUtility,
        unitService,
        commonUtility,
        htmlElementService,
        sanitizeService,
        confirmDialogService
    ) {
        const defaultGroupFilter = [];

        var canChangeState = false;
        var langId = localStorage['NG_TRANSLATE_LANG_KEY'];
        $scope.isSuperUser = $rootScope.authData.isSuperuser;

        window.onbeforeunload = function () {
            if ($('#customers-administration-grid').data('kendoGrid').dataSource.hasChanges()) {
                return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
            }
        };

        $scope.$on('$stateChangeStart', function (event, next) {
            if (!canChangeState && !event.defaultPrevented && $('#customers-administration-grid').data('kendoGrid').dataSource.hasChanges()) {
                event.preventDefault();

                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 300
                    },
                    templateUrl: 'app/shared/popups/confirm-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmModalController',
                    resolve: {
                        content: function () {
                            return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
                        },
                        primaryBtnText: function () {
                            return null;
                        },
                        secondaryBtnText: function () {
                            return null;
                        }
                    }
                });
                windowInstance.result.then(function (response) {
                    if (response) {
                        canChangeState = response;
                        $state.go(next.name);
                    }
                });
            }
        });

        $scope.exportSelected = function () {
            const result = {};

            // First: Process customer checkboxes
            $('#customers-administration-grid tr:not(.k-detail-row) input.custom-checkbox').each(function () {
                const checkbox = $(this);
                const customerId = checkbox.attr('data-customer-id');

                if (customerId && checkbox.prop('checked')) {
                    result[customerId] = [];
                }
            });

            // Second: Process unit checkboxes (only if parent customer isn't selected)
            $('.unit-agreements-grid').each(function () {
                const unitGrid = $(this);
                const parentRow = unitGrid.closest('.k-detail-row').prev();
                const customerId = parentRow.find('input.custom-checkbox').attr('data-customer-id');
                const isParentChecked = parentRow.find('input.custom-checkbox').prop('checked');

                // Skip if customer checkbox is checked, as all units will be included anyway
                if (!isParentChecked) {
                    // Get all checked unit checkboxes and extract their IDs
                    const checkedUnitIds = unitGrid.find('input.custom-checkbox:checked')
                        .map((_, checkbox) => $(checkbox).attr('data-unit-id'))
                        .get();

                    // Only add to result if we found checked units
                    if (checkedUnitIds.length > 0) {
                        result[customerId] = checkedUnitIds;
                    }
                }
            });

            // Check if any selections were made
            const hasSelections = Object.keys(result).length > 0;

            if (!hasSelections) {
                confirmDialogService.openWarningDialog($translate.instant('DOWNLOAD_CSV_NO_SELECTIONS_ERROR'), $translate.instant('G_WARNING'));
                return;
            }

            customerService.exportSelectedUnitAgreements(result).then(function (response) {
                // Create blob from response
                const blob = new Blob([response.data], { type: 'text/csv' });

                let filename = 'export.csv';
                const contentDisposition = response.headers('content-disposition');
                if (contentDisposition !== null) {
                    const dispositions = contentDisposition.split(';')
                        .map(h => h.trim());
                    const utf8Prefix = "filename*=UTF-8''";
                    let name = dispositions
                        .filter(h => h.startsWith(utf8Prefix));
                    if (name.length > 0) {
                        filename = decodeURIComponent(name[0].replace(utf8Prefix, ''));
                    }
                    else {
                        const prefix = "filename=";
                        name = dispositions
                            .filter(h => h.startsWith(prefix));
                        if (name.length > 0) {
                            filename = name[0].replace(prefix, '').replaceAll('"', '');
                        }
                    }
                }
 
                // Create download link
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);

                // Trigger download
                document.body.appendChild(link);
                link.click();

                // Cleanup
                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
            }).catch((e) => {
                console.error('Export error:', e);
                confirmDialogService.openWarningDialog($translate.instant('DOWNLOAD_CSV_UNIT_AGREEMENTS_ERROR'), $translate.instant('G_WARNING'));
            });
        };


        var customersAdministrationDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success($scope.customers),
                update: e => onCustomersGridUpdate(e)
            },
            toolbar: ['save', 'cancel'],
            group: defaultGroupFilter,
            schema: {
                model: {
                    id: 'customerId',
                    fields: {
                        externalCustomerId: { editable: true, validation: { required: true } },
                        name: { editable: true, validation: { required: true } },
                        address: { editable: true },
                        postalCode: { editable: true },
                        contactperson: { editable: true },
                        phone: { editable: true },
                        cellular: { editable: true },
                        email: { editable: true },
                    }
                },
            }
        });

        const customersAdministrationGridOptions = langId => ({
            dataSource: customersAdministrationDataSource,
            height: kendoUtility.calculateRemainingSpace(-40),
            columns: [
                {
                    template: '<input type="checkbox" class="custom-checkbox" data-customer-id="#=id#" />',
                    headerTemplate: '<input type="checkbox" class="custom-checkbox master-checkbox" />',
                    width: 30
                },
                {
                    field: 'customerId',
                    hidden: true
                },
                {
                    field: 'externalCustomerId',
                    title: $translate.instant('ADMIN_CUSTOMERS_CUSTOMER_NUMBER', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.externalCustomerId ? dataItem.externalCustomerId : '';
                    },
                },
                {
                    field: 'name',
                    title: $translate.instant('ADMIN_CUSTOMERS_NAME', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.name ? dataItem.name : '';
                    },
                },
                {
                    field: 'address',
                    title: $translate.instant('ADMIN_CUSTOMERS_ADDRESS', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.address ? dataItem.address : '';
                    },
                },
                {
                    field: 'postalCode',
                    title: $translate.instant('ADMIN_CUSTOMERS_POST_NUMBER', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.postalCode ? dataItem.postalCode : '';
                    },
                },
                {
                    field: 'contactperson',
                    title: $translate.instant('ADMIN_CUSTOMERS_CONTACT_PERSON', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.contactperson ? dataItem.contactperson : '';
                    },
                },
                {
                    field: 'phone',
                    title: $translate.instant('ADMIN_CUSTOMERS_PHONE', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.phone ? dataItem.phone : '';
                    },
                },
                {
                    field: 'cellular',
                    title: $translate.instant('ADMIN_CUSTOMERS_MOBIL_NUMBER', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.cellular ? dataItem.cellular : '';
                    },
                },
                {
                    field: 'email',
                    title: $translate.instant('ADMIN_CUSTOMERS_EMAIL', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.email ? dataItem.email : '';
                    },
                },
                {
                    command: [
                        {
                            name: 'editCustomer',
                            click: e => editCustomer(e),
                            template: '<a class="k-grid-editCustomer"><i class="fas fa-edit"></i></a>'
                        },
                    ],
                    width: 100,
                }
            ],
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                    sanitizeService.sanitizingGridContent(e);
                });
            },
            filterable: true,
            resizable: true,
            sortable: true,
            groupable: true,
            detailInit: detailInit,
            detailTemplate: kendo.template($('#customer-unit-agreements-template').html()),
            dataBound: function () {
                const grid = this;

                // Handle master checkbox
                grid.thead.off('click').on('click', '.master-checkbox', function (e) {
                    const masterCheckbox = $(this);
                    const isChecked = masterCheckbox.prop('checked');

                    // Toggle all customer checkboxes
                    const customerCheckboxes = grid.tbody.find('.custom-checkbox');
                    customerCheckboxes.prop('checked', isChecked);

                    // Toggle all unit checkboxes in expanded rows
                    const detailRows = grid.tbody.find('.k-detail-row');
                    detailRows.find('.custom-checkbox').prop('checked', isChecked);
                });

                // Handle individual customer checkboxes
                grid.tbody.off('click').on('click', '.custom-checkbox', function (e) {
                    const checkbox = $(this);
                    const row = checkbox.closest('tr');
                    const isChecked = checkbox.prop('checked');

                    // Update master checkbox
                    const allCustomerCheckboxes = grid.tbody.find('tr:not(.k-detail-row) .custom-checkbox');
                    const allChecked = allCustomerCheckboxes.length === allCustomerCheckboxes.filter(':checked').length;
                    grid.thead.find('.master-checkbox').prop('checked', allChecked);

                    // Update unit checkboxes
                    const detailRow = row.next('.k-detail-row');
                    if (detailRow.length) {
                        detailRow.find('.custom-checkbox')
                            .prop('checked', isChecked);
                    }
                });
            }
        });

        const unitAgreementsGridOptions = data => ({
            dataSource: data,
            noRecords: true,
            filterable: true,
            sortable: true,
            messages: { noRecords: $translate.instant('G_NORESULTS') },
            columns: [
                {
                    template: '<input type="checkbox" class="custom-checkbox" data-unit-id="#=unitId#" data-customer-id="#=customerId#" />',
                    width: 30
                },
                {
                    hidden: true,
                    field: 'id'
                },
                {
                    field: 'description',
                    title: $translate.instant('ADMIN_CUSTOMERS_DESCRIPTION'),
                    width: 400
                },
                {
                    field: 'standardPrice',
                    title: $translate.instant('ADMIN_CUSTOMERS_STANDARD_PRICE')
                },
                {
                    field: 'unitPrice',
                    title: $translate.instant('ADMIN_CUSTOMERS_UNIT_PRICE'),
                },
                {
                    field: 'fromDate',
                    title: $translate.instant('ADMIN_CUSTOMERS_DATE_FROM'),
                    template: function (dataItem) {
                        return (dataItem.fromDate ? dateUtility.formatDate(dataItem.fromDate) : '');
                    },
                },
                {
                    field: 'toDate',
                    title: $translate.instant('ADMIN_CUSTOMERS_DATE_TO'),
                    template: function (dataItem) {
                        return (dataItem.toDate ? dateUtility.formatDate(dataItem.toDate) : '');
                    },
                },
                {
                    field: 'comment',
                    title: $translate.instant('ADMIN_CUSTOMERS_COMMENT'),
                },
                {
                    field: 'lastCommentedById',
                    title: $translate.instant('ADMIN_CUSTOMERS_LAST_COMMENTED_BY'),
                    template: function (dataItem) {
                        const user = $scope.users.find(u => u.userId == dataItem.lastCommentedById);
                        return dataItem && dataItem.lastCommentedById && user && user.displayName ? user.displayName : "";
                    }
                },
                {
                    command: [
                        {
                            name: 'editCustomerUnitAgreement',
                            click: function (e) {
                                e.preventDefault();
                                var sender = e.currentTarget;
                                var row = angular.element(sender).closest("tr");
                                var parent = $(e.target).closest(".unit-agreements-grid");
                                var data = parent.data("kendoGrid").dataItem(row).toJSON();
                                editCustomerUnitAgreement(data.id, data.customerId, data.unit.contractorId, parent);
                            },
                            template: '<a class="k-grid-editCustomerUnitAgreement"><i class="fas fa-edit"></i></a>'
                        },
                        {
                            name: 'deleteCustomerUnitAgreement',
                            click: function (e) {
                                e.preventDefault();
                                var sender = e.currentTarget;
                                var row = angular.element(sender).closest("tr");
                                var parent = $(e.target).closest(".unit-agreements-grid");
                                var data = parent.data("kendoGrid").dataItem(row).toJSON();
                                deleteCustomerUnitAgreement(data.id, data.unit.contractorId, parent);
                            },
                            template: '<a class="k-grid-deleteCustomerUnitAgreement"><i class="fas fa-trash"></i></a>'
                        }
                    ],
                    width: 80
                }
            ],
            dataBound: function () {
                const unitGrid = this;
                const parentRow = unitGrid.element.closest('.k-detail-row').prev();
                const parentChecked = parentRow.find('.custom-checkbox').prop('checked');

                // If parent customer is checked, automatically check all unit checkboxes
                if (parentChecked) {
                    unitGrid.tbody.find('.custom-checkbox').prop('checked', true);
                }

                // Handle clicks on unit checkboxes
                unitGrid.tbody.off('click').on('click', '.custom-checkbox', function (e) {
                    const allCheckboxes = unitGrid.tbody.find('.custom-checkbox');
                    const checkedBoxes = allCheckboxes.filter(':checked');
                    const parentRow = unitGrid.element.closest('.k-detail-row').prev();
                    const parentCheckbox = parentRow.find('.custom-checkbox');

                    // Toggle parent checkbox
                    parentCheckbox.prop('checked', allCheckboxes.length === checkedBoxes.length);
                });
            }
        });

        $scope.unitAgreementsGridOptions = unitAgreementsGridOptions([]);
        $scope.customersAdministrationGridOptions = customersAdministrationGridOptions($translate.use());
        $scope.customers = [];
        $scope.addNewCustomer = addNewCustomer;
        $scope.saveChanges = saveChanges;
        $scope.cancelChanges = cancelChanges;
        $scope.editCustomer = editCustomer;
        $scope.importPriceAdjustments = importPriceAdjustments;

        $scope.toggleChangesButtons = toggleChangesButtons;
        $scope.$on('langChanged', (event, code) => {
            $scope.customersAdministrationGridOptions = customersAdministrationGridOptions(code);
            const grid = kendoUtility.createWidget('kendoGrid', $scope.customersAdministrationGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#customers-administration-grid')]);
            langId = code;

        });

        initController();
        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'customersAdministrationGrid',
                destination: '#customers-administration-grid'
            });
            htmlElementService.checkIfElementExists("filter-group")
                .then(function (result) {
                    commonUtility.calculateAdminTabMenuWidth();
                });
            toggleChangesButtons(true)
            let requests = {};
            requests.getCustomers = customerService.getCustomersByContractorId($rootScope.authData.contractorId);
            requests.getUsers = userService.getAllUser();
            $q.all(requests)
                .then(data => {
                    $scope.customers = data.getCustomers;
                    $scope.users = data.getUsers;
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $scope.customersAdministrationGridOptions.dataSource.read();
                    $rootScope.$broadcast('hideBusyIndicator', 'customersAdministrationGrid');
                });
        }

        function openCustomersDetailsModal(formType, customer) {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: customer ? customer.name : $translate.instant('ADMIN_CUSTOMERS_NEW'),
                    resizable: true,
                    visible: false,
                    width: 500
                },
                templateUrl: 'app/administration/customers/customers-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'CustomersDetailsModalController',
                resolve: {
                    currentFormType: () => formType,
                    customer: () => customer,
                }
            });
        }

        function addNewCustomer() {
            const window = openCustomersDetailsModal(formTypes.add);
            window.result.then(result => {
                if (result) {
                    $scope.customers = $scope.customers.concat([result]);
                    $scope.customersAdministrationGridOptions.dataSource.read();
                }
            });
        }

        function editCustomer(event) {
            const window = openCustomersDetailsModal(formTypes.edit, getCustomerFromGrid(event));
            window.result.then(result => handleUpdate(result));
        }

        function getCustomerFromGrid(event) {
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $('#customers-administration-grid')
                .data('kendoGrid')
                .dataItem(row)
                .toJSON();
        }

        function importPriceAdjustments() {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_CUSTOMERS_IMPORT_PRICE_ADJUSTMENTS'),
                    resizable: false,
                    visible: false,
                    width: 550
                },
                templateUrl: 'app/shared/popups/import-price-adjustments-from-csv-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'importPriceAdjustmentsFromCsvModalController',
                resolve: {
                }
            });
        }

        function handleUpdate(customer) {
            if (customer) {
                $scope.customers = $scope.customers.map(u => (u.customerId === customer.customerId ? customer : u));
                $scope.customersAdministrationGridOptions.dataSource.read();
            }
        }

        function detailInit(e) {
            var customerId = e.data.customerId;
            customerService.getCustomersUnitAgreements(customerId).then(function (data) {
                extendCustomerUnitAgreements(data)
                data = data.sort((a, b) => a.description.localeCompare(b.description));
                $scope.customerUnitAgreements = data;
                const element = e.detailRow.find('.unit-agreements-grid');
                var table = $(element);
                $scope.unitAgreementsGridOptions = unitAgreementsGridOptions(getCustomerUnitAgreementDataSource(data));
                element.kendoGrid($scope.unitAgreementsGridOptions);
                $(`#${customerId}`)
                    .text($translate.instant('G_NEWITEM'))
                    .click(() => addCustomerUnitAgreement(customerId, e.data.contractorId, table));
            });
        }

        function extendCustomerUnitAgreements(unitAgreements) {
            _.forEach(unitAgreements, function (item) {
                if (item.unit) {
                    item.standardPrice = item.unit.standardPrice;
                    item.description = item.unit.description;
                }
            });
        }

        function getCustomerUnitAgreementDataSource(unitAgreements) {
            return new kendo.data.DataSource({
                transport: {
                    read: e => e.success(unitAgreements),
                },
                schema: {
                    model: {
                        id: 'id',
                        fields: {
                            description: { editable: false },
                            standardPrice: { editable: false },
                            unitPrice: { editable: false },
                            fromDate: { editable: false, type: 'date' },
                            toDate: { editable: false, type: 'date' },
                            comment: { editable: false },
                            lastCommentedById: { editable: false },
                        }
                    },
                }
            });
        }

        function openCustomerUnitAgreement(currentFormType, customerUnitAgreement, customerId, units, contractorId, table) {
            const dialogTitle = currentFormType == 'edit' ?
                $translate.instant('ADMIN_CUSTOMERS_EDIT_UNIT_AGREEMENT')
                : $translate.instant('ADMIN_CUSTOMERS_ADD_UNIT_AGREEMENT');

            const windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: dialogTitle,
                    resizable: true,
                    width: 520,
                    visible: false
                },
                templateUrl: 'app/administration/customers/customers-unit-agreement-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'customersUnitAgreementModalController',
                resolve: {
                    currentFormType: () => currentFormType,
                    customerUnitAgreement: () => customerUnitAgreement,
                    customerId: () => customerId,
                    units: () => units
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    updateUnitAgreementGrid(customerId, contractorId, table);
                }
            });
        }

        function addCustomerUnitAgreement(customerId, contractorId, table) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'customersAdministrationGrid',
                destination: '#customers-administration-grid'
            });
            let requests = {};
            requests.getUnitsByContractorId = unitService.getUnitsAsDropdownByContractorId(contractorId);
            $q.all(requests)
                .then(data => {
                    var units = data.getUnitsByContractorId;
                    openCustomerUnitAgreement(formTypes.add, null, customerId, units, contractorId, table)
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'customersAdministrationGrid');
                });
        }

        function editCustomerUnitAgreement(unitAgreementId, customerId, contractorId, table) {

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'customersAdministrationGrid',
                destination: '#customers-administration-grid'
            });
            customerService.getCustomerUnitAgreementById(unitAgreementId).then(function (unitAgreement) {
                if (unitAgreement) {
                    let requests = {};
                    requests.getUnitsByContractorId = unitService.getUnitsAsDropdownByContractorId(contractorId);
                    $q.all(requests)
                        .then(data => {
                            var units = data.getUnitsByContractorId;
                            openCustomerUnitAgreement(formTypes.edit, unitAgreement, customerId, units, contractorId, table)
                        })
                        .catch(error => console.error(error))
                        .finally(() => {
                            $rootScope.$broadcast('hideBusyIndicator', 'customersAdministrationGrid');
                        });
                }
            });
        }

        function deleteCustomerUnitAgreement(unitAgreementId, contractorId, table) {

            customerService.getCustomerUnitAgreementById(unitAgreementId).then(function (unitAgreement) {
                if (unitAgreement) {
                    const windowInstance = $kWindow.open({
                        options: {
                            modal: true,
                            movable: false,
                            resizable: false,
                            visible: false,
                            width: 300
                        },
                        templateUrl: 'app/shared/popups/confirm-modal.html',
                        windowTemplateUrl: 'app/shared/modal-base.html',
                        controller: 'ConfirmModalController',
                        resolve: {
                            content: function () {
                                return $translate.instant('G_CONFIRM_DELETE');
                            },
                            primaryBtnText: function () {
                                return null;
                            },
                            secondaryBtnText: function () {
                                return null;
                            }
                        }
                    });
                    windowInstance.result.then(function (response) {
                        if (response) {
                            $rootScope.$broadcast('showBusyIndicator', {
                                id: 'customersAdministrationGrid',
                                destination: '#customers-administration-grid'
                            });
                            customerService.deleteUnitAgreement(unitAgreement.id).then(function (result) {
                                if (result) {
                                    updateUnitAgreementGrid(unitAgreement.customerId, contractorId, table);
                                }

                            })
                                .finally(function () {
                                    $rootScope.$broadcast('hideBusyIndicator', 'customersAdministrationGrid');
                                });
                        }
                    });
                }
            });
        }

        function updateUnitAgreementGrid(customerId, contractorId, table) {
            customerService.getCustomersUnitAgreements(customerId).then(function (data) {
                extendCustomerUnitAgreements(data)
                $scope.customerUnitAgreements = data;
                const element = table;
                element.kendoGrid('destroy').empty();
                $scope.unitAgreementsGridOptions = unitAgreementsGridOptions(getCustomerUnitAgreementDataSource(data));
                element.kendoGrid($scope.unitAgreementsGridOptions);
            });
        }

        function saveChanges() {
            $scope.customersAdministrationGridOptions.dataSource.sync();
        }

        function cancelChanges() {
            toggleChangesButtons(true)
            $scope.customersAdministrationGridOptions.dataSource.read();
        }

        function toggleChangesButtons(disabled) {
            var saveChangesButton = $('button.k-button.k-grid-save-changes-customers');
            var cancelChangesButton = $('button.k-button.k-grid-cancel-changes-customers');
            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function onCustomersGridUpdate(e) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'customersAdministrationGrid',
                destination: '#customers-administration-grid'
            });
            customerService.updateCustomer(e.data)
                .then(function (response) {
                    if (response) {
                        toggleChangesButtons(true);
                        e.success();
                        $rootScope.$broadcast('hideBusyIndicator', 'customersAdministrationGrid');
                    } else {
                        e.error();
                        $rootScope.$broadcast('hideBusyIndicator', 'customersAdministrationGrid');
                    }
                }, function (error) {
                    e.error(error);
                    $rootScope.$broadcast('hideBusyIndicator', 'customersAdministrationGrid');
                });

        }
    }
})();
