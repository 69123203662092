(function() {
    'use strict';

    angular.module('NaviaqWebApp').controller('LogActionWithCheckListItemsModalController', logActionWithCheckListItemsModalController);

    logActionWithCheckListItemsModalController.$inject = [
        '$scope',
        '$q',
        '$rootScope',
        '$windowInstance',
        '$translate',
        'authService',
        'logService',
        'baseDataService',
        'formType',
        'lists',
        'users',
        'departments',
        'jobDepartment',
        'selectedEquipment'
    ];

    function logActionWithCheckListItemsModalController(
        $scope,
        $q,
        $rootScope,
        $windowInstance,
        $translate,
        authService,
        logService,
        baseDataService,
        formType,
        lists,
        users,
        departments,
        jobDepartment,
        selectedEquipment
    ) {
        $scope.authData = authService.getAuthData();
        $scope.formType = formType;

        $scope.jobDepartment = jobDepartment;
        $scope.selectedEquipment = selectedEquipment;

        //log action form
        $scope.logAction = null;
        $scope.exportLogAction = null;
        $scope.measurementUnitTypes = [];
        $scope.selectedDepartmentsForLogAction = [jobDepartment];
        $scope.isComponentLog = false;
        $scope.isCustomerDeviation = false;
        $scope.hasValue = false;
        $scope.valueName = null;
        $scope.hasFileToLogAction = false;
        $scope.selectedJobType = null;
        $scope.isDescriptionRequired = false;
        $scope.isPrivate = false;
        $scope.userListNeeded = false;
        $scope.logActionDescription = '';
        $scope.selectedLogActionType = null;
        $scope.selectedLogActionList = null;
        $scope.selectedLogActionUser = null;
        $scope.showRequiredDescription = false;

        //Checklist item form
        $scope.checkListItems = [];
        $scope.selectedChecklistItem = null;
        $scope.description = '';
        $scope.selectedMeasurementUnitType = null;
        $scope.additionalInformation = '';

        //Form navigation
        $scope.currentStep = 1;
        $scope.previousStep = previousStep;
        $scope.nextStep = nextStep;

        //LogAction form values
        $scope.logActionTypeOptions = {
            dataSource: new kendo.data.DataSource({
                data: logService.getLogActionTypesForEquipmentJobs()
            }),
            dataValueField: 'value',
            dataTextField: 'name',
            optionLabel: $translate.instant('ADMIN_LOGACTION_MODAL_SELECTTYPE')
        };

        $scope.logActionListOptions = {
            dataSource: new kendo.data.DataSource({
                data: lists
            }),
            dataValueField: 'id',
            dataTextField: 'description',
            optionLabel: $translate.instant('ADMIN_LOGACTION_MODAL_SELECTLIST')
        };

        $scope.logActionUserOptions = {
            dataSource: new kendo.data.DataSource({
                data: users
            }),
            dataValueField: 'userId',
            dataTextField: 'displayName',
            optionLabel: $translate.instant('ADMIN_LOGACTION_MODAL_SELECTUSER')
        };

        $scope.jobTypeOptions = {
            dataSource: new kendo.data.DataSource({
                data: logService.getJobTypes()
            }),
            dataValueField: 'value',
            dataTextField: 'name',
            optionLabel: $translate.instant('ADMIN_LOGACTION_MODAL_SELECTJOBTYPE')
        };

        $scope.logActionDepartmentsOptions = {
            dataSource: new kendo.data.DataSource({
                data: departments
            }),
            dataValueField: 'departmentId',
            dataTextField: 'name',
            noDataTemplate: 'Ingen treff'
        };

        //Checklist items form values
        $scope.measurementUnitTypeOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.measurementUnitTypes)
                }
            }),
            dataValueField: 'measurementUnitTypeId',
            dataTextField: 'measurementUnitDescription',
            optionLabel: $translate.instant('ADMIN_LOGACTION_CHECKLISTMODAL_SELECTMEASUREMENTTYPE')
        };

        $scope.checklistItemListOptions = {
            connectWith: 'list-of-added-checklist-items',
            dataSource: new kendo.data.DataSource({
                data: $scope.checkListItems
            }),
            dataTextField: 'description',
            noDataTemplate: 'Ingen treff',
            change: e => {
                $scope.selectedChecklistItem = e.sender.dataItem(e.sender.select());
            }
        };

        $scope.closeModal = closeModal;
        $scope.validateLogActionForm = validateLogActionForm;
        $scope.validateCheckListItemsForm = validateCheckListItemsForm;
        $scope.removeSelectedChecklistItem = removeSelectedChecklistItem;
        $scope.saveForm = saveForm;


        initController();

        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'logActionWithChecklistItemsIndicator',
                destination: '#log-action-with-checklist-items-form',
                overlay: true
            });

            const requests = {
                getMeasurementUnitTypesWebapi: baseDataService.getMeasurementUnitTypesWebapi()
            };

            $q.all(requests).then(function(data) {
                $scope.measurementUnitTypes = data.getMeasurementUnitTypesWebapi;
            }).finally(() => {
                $scope.measurementUnitTypeOptions.dataSource.read();
                $rootScope.$broadcast('hideBusyIndicator', 'logActionWithChecklistItemsIndicator');
            });

            setModule();
        }

        function setModule() {
            if (authService.hasModule('RequiredDescriptionInLog')) {
                $scope.showRequiredDescription = true;
            }
        }

        function validateLogActionForm(event) {
            let logActionForm = document.getElementById('log-action-form-step-1');
            if (logActionForm.checkValidity()) {
                let departmentIds = [];
                if ($scope.selectedDepartmentsForLogAction && $scope.selectedDepartmentsForLogAction.length > 0) {
                    $scope.selectedDepartmentsForLogAction.forEach(function(department) {
                        departmentIds.push(department.departmentId);
                    });
                }

                $scope.logAction = {
                    description: $scope.logActionDescription,
                    logActionType: $scope.selectedLogActionType.value,
                    listId:
                        $scope.selectedLogActionList && $scope.selectedLogActionList.id !== ''
                            ? $scope.selectedLogActionList.id
                            : null,
                    defaultUserId:
                        $scope.selectedLogActionUser && $scope.selectedLogActionUser.userId !== ''
                            ? $scope.selectedLogActionUser.userId
                            : null,
                    userListNeeded: $scope.userListNeeded,
                    isPrivate: $scope.isPrivate,
                    isDescriptionRequired: $scope.isDescriptionRequired,
                    jobType:
                        $scope.selectedJobType && $scope.selectedJobType.value !== ''
                            ? $scope.selectedJobType.value
                            : null,
                    hasValue: $scope.hasValue,
                    hasFile: $scope.hasFileToLogAction,
                    valueName: $scope.valueName,
                    isCustomerDeviation: $scope.isCustomerDeviation,
                    isComponentLog: $scope.isComponentLog,
                    exportLogAction: $scope.exportLogAction,
                    contractorId: $scope.authData.contractorId,
                    departmentIds: departmentIds
                };

                $scope.nextStep();
            }
        }

        function validateCheckListItemsForm(event) {
            let checklistForm = document.getElementById('check-list-items-form-step-2');

            if (checklistForm.checkValidity()) {
                // Check for duplicate description
                let duplicateIndex = $scope.checkListItems.findIndex(function(item) {
                    return item.description.toLowerCase() === $scope.description.toLowerCase();
                });

                if (duplicateIndex >= 0) {
                    alert('An item with this description already exists. Please use a unique description.');
                    return;
                }

                // Add the new item with ternary checks for null values
                $scope.checkListItems.push({
                    description: $scope.description,
                    additionalInformation: $scope.additionalInformation || null,
                    measurementUnitTypeId: $scope.selectedMeasurementUnitType ? $scope.selectedMeasurementUnitType.measurementUnitTypeId : null
                });

                // Refresh the DataSource
                $scope.checklistItemListOptions.dataSource.read();

                // Clear the form fields after adding
                $scope.description = '';
                $scope.additionalInformation = '';
                $scope.selectedMeasurementUnitType = null;
                $scope.selectedChecklistItem = null;
            }
        }

        function removeSelectedChecklistItem() {
            if ($scope.selectedChecklistItem == null) {
                return;
            }

            // Remove selected items from the array
            let index = $scope.checkListItems.findIndex(x => x.description == $scope.selectedChecklistItem.description);
            $scope.checkListItems.splice(index, 1);

            // Clear selection
            $scope.selectedChecklistItem = null;

            // Refresh the datasource
            $scope.checklistItemListOptions.dataSource.read();
        }

        function closeModal() {
            if ($scope.logAction == null && $scope.checkListItems.length == 0) {
                $windowInstance.close();
            }

            if ($scope.logAction.description != null || $scope.checkListItems.length > 0) {
                if (confirm($translate.instant('G_UNSAVED_CHANGES_WARNING'))) {
                    $windowInstance.close();
                } else {
                    return;
                }
            }
        }

        function nextStep() {
            $scope.currentStep++;
        }

        function previousStep() {
            $scope.currentStep--;
        }

        function saveForm(event) {
            let request = {
                logAction: $scope.logAction,
                checklistItems: $scope.checkListItems
            };

            logService.addLogActionWithChecklistItems(request)
                .then((response) => $windowInstance.close({ result: true, data: response }));
        }
    }
})();
