(function () {
    'use strict';

    angular.module('NaviaqWebApp').directive('fileImporter', fileImporter);

    fileImporter.$inject = [];

    function fileImporter() {
        return {
            controller: ['$rootScope', '$scope', '$q', 'fileHandlingService', 'commonUtility', '$translate', fileImporter],
            replace: true,
            restrict: 'E',
            templateUrl: 'app/shared/directives/file-importer-directive.html',
            scope: {
                documents: '=documents',
                importerId: '=importerId',
                comment: '=comment',
                multipleUpload: "=multipleUpload"
            },
        };

        function fileImporter($rootScope, $scope, $q, fileHandlingService, commonUtility, $translate) {
            $scope.openFilesModal = openFilesModal;
            $scope.handleFileImport = handleFileImport;
            $scope.removeSelectedFile = removeSelectedFile;

            $scope.fileImportBtnId = $scope.importerId + 'ImportFileType';
            $scope.documents = $scope.documents ? $scope.documents : [];
            $scope.comment = null;

            setTimeout(function () {
                $scope.$on(`${$scope.importerId}GetSelectedFiles`, function () {
                    getSelectedFiles();
                });

                $scope.$on(`${$scope.importerId}OpenFileSelector`, function (_, file) {
                    openFileSelectorModal(file);
                });

            }, 500);

            initDirective();
            function initDirective() { }

            function openFilesModal() {
                $(`#${$scope.fileImportBtnId}`).click();
            }

            function handleFileImport(e) {
                _.forEach($scope.fileArray, function (file) {
                    if (!fileHandlingService.validationFileExtension(file)) {
                        $scope.fileArray = [];
                    }
                });

                $scope.base64Contents = [];
                var requests = [];
                _.forEach($scope.fileArray, function (file) {
                    requests.push(fileHandlingService.asyncReadGenericFileWithName(file));
                });

                $q.all(requests).then(function (base64Files) {
                    _.forEach(base64Files, function (file) {
                        $scope.documents.push({
                            id: null,
                            fileName: file.fileName,
                            Base64FileName: commonUtility.base64Encode(file.fileName),
                            url: null,
                            base64Content: file.base64Content,
                            createdOn: new Date(),
                            description: null,
                            selectedComponentId: null,
                            displayText: null,
                        });
                    });
                    $rootScope.$broadcast(`${$scope.importerId}AddNewFileToSelector`);
                });
            }

            function removeSelectedFile(fileName) {
                $scope.documents = _.reject($scope.documents, file => file.fileName === fileName);
            }

            function getSelectedFiles() {
                let selectedFiles = [];
                if ($scope.documents && $scope.documents.length > 0) {
                    selectedFiles = $scope.documents;
                }

                //use emit to return back up to the calling modal
                $scope.$emit(`${$scope.importerId}ReturnSelectedFiles`, selectedFiles);

            }
        }
    }
})();
