(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('UnitsAdministrationController', UnitsAdministrationController);

    UnitsAdministrationController.$inject = [
        '$q',
        '$scope',
        '$state',
        '$rootScope',
        '$kWindow',
        '$translate',
        'kendoUtility',
        'formTypes',
        'unitService',
        'baseDataService',
        'locationService',
        'mediaService',
        'commonUtility',
        'htmlElementService',
        'sanitizeService',
        'dateUtility',
        'confirmDialogService'
    ];

    function UnitsAdministrationController(
        $q,
        $scope,
        $state,
        $rootScope,
        $kWindow,
        $translate,
        kendoUtility,
        formTypes,
        unitService,
        baseDataService,
        locationService,
        mediaService,
        commonUtility,
        htmlElementService,
        sanitizeService,
        dateUtility,
        confirmDialogService
    ) {
        let expandedRowIds = [];
        const defaultGroupFilter = [];

        var canChangeState = false;
        var langId = localStorage['NG_TRANSLATE_LANG_KEY']

        window.onbeforeunload = function () {
            if ($('#units-administration-grid').data('kendoGrid').dataSource.hasChanges()) {
                return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
            }
        };

        $scope.$on('$stateChangeStart', function (event, next) {
            if (!canChangeState && !event.defaultPrevented && $('#units-administration-grid').data('kendoGrid').dataSource.hasChanges()) {
                event.preventDefault();

                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 300
                    },
                    templateUrl: 'app/shared/popups/confirm-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmModalController',
                    resolve: {
                        content: function () {
                            return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
                        },
                        primaryBtnText: function () {
                            return null;
                        },
                        secondaryBtnText: function () {
                            return null;
                        }
                    }
                });
                windowInstance.result.then(function (response) {
                    if (response) {
                        canChangeState = response;
                        $state.go(next.name);
                    }
                });
            }
        });


        var unitsAdministrationDataSource = new kendo.data.DataSource({
            transport: {
                read: function (e) {
                    var activeFilter = {
                        field: "IsActive",
                        operator: "contains",
                        value: "1"
                    };
                    !e.data.filter
                        ? e.data.filter = { filters: [] }
                        : e.data.filter.filters;

                    e.data.filter.filters.push(activeFilter)

                    unitService.getUnitsByContractorIdWithUnitGroup(
                        $rootScope.authData.contractorId,
                        e.data.page,
                        e.data.pageSize,
                        e.data.filter,
                        e.data.sort
                    ).then(response => {
                        $scope.units = response.items;
                        e.success({
                            data: response.items,
                            total: response.totalCount
                        });
                    });
                },
                update: e => onUnitsGridUpdate(e)
            },
            toolbar: ['save', 'cancel'],
            group: defaultGroupFilter,
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            pageSize: 25,
            schema: {
                data: "data",
                total: "total",
                model: {
                    id: 'id',
                    fields: {
                        unitGroupId: { editable: true, validation: { required: true } },
                        externalUnitId: { editable: true },
                        description: { editable: true },
                        supplierName: { editable: false },
                        measurementUnitTypeId: { editable: true },
                        resourceId: { editable: true },
                        standardPrice: { editable: true },
                        inPrice: { editable: true },
                    }
                },
            }
        });

        function isBatchValid(batch) {
            return {
                hasCertificate: !!batch.mediaId,
                hasLifetime: !!batch.lifetimeInDays,
                hasValidLifetime: batch.lifetimeInDays > 0,
                hasCertificateDate: !!batch.certificateIssuedDate,
                isComplete: !!batch.mediaId &&
                    !!batch.lifetimeInDays &&
                    batch.lifetimeInDays > 0 &&
                    !!batch.certificateIssuedDate
            };
        }

        const unitsAdministrationGridOptions = langId => ({
            dataSource: unitsAdministrationDataSource,
            height: kendoUtility.calculateRemainingSpace(-40),
            pageable: {
                pageSize: 25,
                refresh: true,
                pageSizes: [25, 50, 100],
                buttonCount: 5,
                messages: {
                    display: $translate.instant('G_PAGING_DISPLAY'),
                    empty: $translate.instant('G_PAGING_EMPTY'),
                    page: $translate.instant('G_PAGING_PAGE'),
                    of: $translate.instant('G_PAGING_OF'),
                    itemsPerPage: $translate.instant('G_PAGING_ITEMSPERPAGE'),
                    first: $translate.instant('G_PAGING_FIRST'),
                    previous: $translate.instant('G_PAGING_PREVIOUS'),
                    next: $translate.instant('G_PAGING_NEXT'),
                    last: $translate.instant('G_PAGING_LAST'),
                    refresh: $translate.instant('G_PAGING_REFRESH')
                }
            },
            columns: [
                {
                    field: 'id',
                    hidden: true
                },
                {
                    field: 'unitGroupId',
                    title: $translate.instant('ADMIN_UNITS_PRODUCT_GROUP'),
                    template: function(dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        let item = $scope.unitGroups.find(function(unitGroup) {
                            return unitGroup.id === dataItem.unitGroupId;
                        });

                        if (item) {
                            dataItem.unitGroup = item;
                            dataItem.unitGroupDescription = item.description;
                        }
                        return dataItem.unitGroupDescription ? dataItem.unitGroupDescription : '';
                    },
                    editor: unitGroupsDropDownEditor,
                    groupHeaderTemplate: $translate.instant('ADMIN_UNITS_PRODUCT_GROUP') + ": #=items[0] && items[0].unitGroupDescription ? items[0].unitGroupDescription : '' #"
                },
                {
                    field: 'externalUnitId',
                    title: $translate.instant('ADMIN_UNITS_PRODUCT_NUMBER'),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.externalUnitId ? sanitizeService.sanitizingStringContent(dataItem.externalUnitId) : '';
                    },
                },
                {
                    field: 'description',
                    title: $translate.instant('ADMIN_UNITS_PRODUCT_NAME'),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.description ? sanitizeService.sanitizingStringContent(dataItem.description) : '';
                    },
                },
                {
                    field: 'supplierName',
                    title: $translate.instant('ADMIN_UNITS_SUPPLIER'),
                    template: function(dataItem) {
                        const hasSupplier = dataItem.supplier !== null;
                        dataItem.supplierName = hasSupplier ? dataItem.supplier.name : '';
                        const warningIcon = !hasSupplier ?
                            '<i class="fas fa-exclamation-triangle text-warning" title="' +
                            $translate.instant('ADMIN_UNITS_MISSING_SUPPLIER') + '"></i> ' : '';
                        return warningIcon + dataItem.supplierName;
                    },
                },
                {
                    field: 'measurementUnitTypeId',
                    title: $translate.instant('ADMIN_UNITS_MEASUREMENT_UNIT'),
                    template: function(dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }

                        let item = $scope.measurementTypes.find(function(measurementType) {
                            return measurementType.measurementUnitTypeId === dataItem.measurementUnitTypeId;
                        });

                        if (item) {
                            dataItem.measurementUnitType = item;
                            dataItem.measurementUnitTypeName = item.measurementUnitDescription;
                        }
                        return dataItem.measurementUnitTypeName ? dataItem.measurementUnitTypeName : '';
                    },
                    editor: measurementUnitTypeDropDownEditor,
                    groupHeaderTemplate: $translate.instant('ADMIN_UNITS_MEASUREMENT_UNIT', null, null, langId) + ": #=items[0] && items[0].measurementUnitTypeName ? items[0].measurementUnitTypeName : '' #"
                },
                {
                    field: 'resourceId',
                    title: $translate.instant('ADMIN_UNITS_RESOURCE'),
                    template: function(dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        if (dataItem.resourceId) {
                            let item = $scope.resources.find(function(resource) {
                                return resource.resourceId == dataItem.resourceId;
                            });

                            if (item) {
                                dataItem.resource = item;
                                dataItem.resourceName = item.resourceDescription;
                            }
                        }

                        return dataItem.resourceName ? dataItem.resourceName : '';
                    },
                    editor: resourceDropDownEditor,
                    groupHeaderTemplate: $translate.instant('ADMIN_UNITS_RESOURCE') + ": #=items[0] && items[0].resourceName ? items[0].resourceName : '' #",
                },
                {
                    field: 'standardPrice',
                    title: $translate.instant('ADMIN_UNITS_STANDARD_PRICE'),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.standardPrice ? sanitizeService.sanitizingStringContent(dataItem.standardPrice) : '';
                    },
                },
                {
                    field: 'inPrice',
                    title: $translate.instant('ADMIN_UNITS_IN_PRICE'),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.inPrice ? sanitizeService.sanitizingStringContent(dataItem.inPrice) : '';
                    },
                },
                {
                    command: [
                        {
                            name: 'editUnit',
                            click: e => editUnit(e),
                            template: `<a class="k-grid-editUnit"><i class="fas fa-edit" title="${$translate.instant('G_EDIT') }"></i></a>`
                        },
                        {
                            name: 'archiveUnit',
                            template: `<a class="k-grid-archiveUnit"><i class="fa fa-archive" title="${$translate.instant('ADMIN_UNITS_ARCHIVE_BUTTON_TEXT') }"></i></a>`,
                            click: e => toggleUnitArchive(e),  
                        },
                    ],
                    width: 100,
                }
            ],
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                    sanitizeService.sanitizingGridContent(e);
                });
            },
            filterable: {
                name: "FilterMenu",
                extra: false,
                operators: {
                    string: {
                        contains: $translate.instant('G_SEARCH_OPTION_CONTAINS', null, null, langId),
                    }
                },
            },
            filterMenuInit: function (e) {
                if (e.field === "resourceName" || e.field === "supplierName") {
                    let filterMultiCheck = this.thead.find("[data-field=" + e.field + "]").data("kendoFilterMenu");
                    filterMultiCheck.container.empty();
                    filterMultiCheck.checkSource.sort({
                        field: e.field,
                        dir: "asc"
                    });
                    filterMultiCheck.checkSource.filter([]);
                    filterMultiCheck.checkSource.data(filterMultiCheck.checkSource.view().toJSON());
                    filterMultiCheck.createCheckBoxes();
                }
            },
            resizable: true,
            sortable: true,
            groupable: true,
            detailInit: detailInit,
            detailCollapse: (e) => {
                const dataGrid = $('#units-administration-grid').data('kendoGrid');
                const dataItems = dataGrid.dataSource.view();

                const dataItem = dataItems.find(x => x.uid === e.masterRow.data('uid'));

                if (dataItem) {
                    expandedRowIds = expandedRowIds.filter(x => x !== dataItem.id);
                }
            },
            detailTemplate: kendo.template($('#customer-unit-batch-template').html()),
            dataBound: function () {
                const dataGrid = $('#units-administration-grid').data('kendoGrid');
                const dataItems = dataGrid.dataSource.view();

                // reopen expanded rows on grid refresh
                $.each(expandedRowIds, function (_, v) {
                    $.each(dataItems, function (_, y) {
                        if (v === y.id) {
                            dataGrid.expandRow(dataGrid.tbody.find("tr[data-uid='" + y.uid + "']"));
                        }
                    });
                });
            }
        });

        var unitBatchGridOptions = data => ({
            dataSource: {
                data,
                schema: {
                    model: {
                        id: 'id',
                    },
                }
            },
            noRecords: true,
            messages: {
                noRecords: $translate.instant('G_NORESULTS')
            },
            persistSelection: true,
            change: function (e) {
                var id = this.element[0].id.split("_")[0];
                if (id && this.select() && this.select().length > 0) {
                    document.getElementById(`${id}_move`).style.visibility = 'visible';
                } else {
                    document.getElementById(`${id}_move`).style.visibility = 'hidden';
                }
            },
            columns: [{
                    hidden: true,
                    field: 'id'
                },
                {
                    field: 'batch',
                    title: $translate.instant('ADMIN_UNITS_BATCH_NUMBER'),
                    width: "350px"
                },
                {
                    field: 'mediaId',
                    title: $translate.instant('ADMIN_UNITS_CERTIFICATE'),
                    width: "350px",
                    template: function (dataItem) {
                        const warningIcon = !dataItem.mediaId ?
                            '<i class="fas fa-exclamation-triangle text-warning" title="' +
                            $translate.instant('ADMIN_UNITS_MISSING_CERTIFICATE') + '"></i> ' : '';

                        const text = dataItem.mediaId ?
                            $translate.instant('ADMIN_UNITS_VIEW_CERTIFICATE', null, null, langId) :
                            $translate.instant('ADMIN_UNITS_UPLOAD_CERTIFICATE', null, null, langId);

                        return warningIcon + '<button class="k-button btn btn-default k-grid-certificate" data-id="' +
                            dataItem.id + '" data-mediaid="' + dataItem.mediaId + '">' + text + '</button>';
                    },
                },
                {
                    field: 'certificateIssuedDate',
                    title: $translate.instant('UNIT_BATCH_CERTIFICATE_ISSUED_DATE'),
                    width: "150px",
                    template: function (dataItem) {
                        const warningIcon = !dataItem.certificateIssuedDate ?
                            '<i class="fas fa-exclamation-triangle text-warning" title="' +
                            $translate.instant('ADMIN_UNITS_MISSING_CERTIFICATE_DATE') + '"></i> ' : '';
                        return warningIcon + (dataItem.certificateIssuedDate ? dateUtility.formatDate(dataItem.certificateIssuedDate) : '');
                    },
                },
                {
                    field: 'isActive',
                    title: $translate.instant('ADMIN_UNITS_ACTIVE'),
                    width: "40px",
                    template: function (dataItem) {
                        const checked = dataItem.isActive ? 'checked' : '';
                        return `<div class="center-content"><input type="checkbox" class="is-active-checkbox" ${checked} disabled/></div>`;
                    },
                },
                {
                    field: 'lifetimeInDays',
                    title: $translate.instant('ADMIN_UNITS_LIFETIME_IN_DAYS'),
                    width: "350px",
                    template: function (dataItem) {
                        const validity = isBatchValid(dataItem);
                        const warningIcon = (!validity.hasLifetime || !validity.hasValidLifetime) ?
                            '<i class="fas fa-exclamation-triangle text-warning" title="' +
                            $translate.instant('ADMIN_UNITS_INVALID_LIFETIME') + '"></i> ' : '';
                        return warningIcon + (dataItem.lifetimeInDays || '');
                    },
                },
                {
                    field: 'locations',
                    title: $translate.instant('ADMIN_UNITS_LOCATIONS'),
                    width: "350px",
                    template: function (dataItem) {
                        var locations = ''
                        _.forEach(dataItem.locations, function (location) {
                            locations = locations + location.name + ", "
                        });
                        locations = locations.substr(0, locations.length - 2)

                        return locations;
                    },
                },
                {
                    selectable: true,
                    width: "40px"
                },
                {
                    command: [{
                        name: 'editUnitBatch',
                        click: function (e) {
                            e.preventDefault();
                            var sender = e.currentTarget;
                            var row = angular.element(sender).closest("tr");
                            var data = $(`#${e.delegateTarget.id}`).data("kendoGrid").dataItem(row).toJSON();
                            editUnitBatch(data.unitId, data.id);
                        },
                        template: '<a class="k-grid-editUnitBatch"><i class="fas fa-edit"></i></a>'
                    }],
                    width: 80
                }
            ],
            dataBound: function (e) {
                var grid = e.sender;
                var rows = grid.tbody.find("tr");

                rows.each(function (index, row) {
                    var dataItem = grid.dataItem(row);
                    var validity = isBatchValid(dataItem);

                    if (!validity.isComplete) {
                        $(row).addClass('incomplete-row');
                    }
                });
            },
        });

        $scope.unitsAdministrationGridOptions = unitsAdministrationGridOptions($translate.use());
        $scope.unitBatchGridOptions = unitBatchGridOptions([]);       
        $scope.toggleUnitArchive = toggleUnitArchive;
        $scope.units = [];
        $scope.addNewUnit = addNewUnit;
        $scope.saveChanges = saveChanges;
        $scope.cancelChanges = cancelChanges;
        $scope.editUnit = editUnit;
        $scope.unitGroups = [];
        $scope.measurementTypes = [];
        $scope.resources = [];

        $scope.toggleChangesButtons = toggleChangesButtons;
        $scope.$on('langChanged', (event, code) => {
            $scope.unitsAdministrationGridOptions = unitsAdministrationGridOptions(code);
            const grid = kendoUtility.createWidget('kendoGrid', $scope.unitsAdministrationGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#units-administration-grid')]);
            langId = code;
        });

        function unitGroupsDropDownEditor(container, options) {
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    optionLabel: $translate.instant('ADMIN_UNITS_SELECT_PRODUCT_GROUP'),
                    dataTextField: 'description',
                    dataValueField: 'id',
                    dataSource: {
                        data: $scope.unitGroups
                    }
                });
        }

        function measurementUnitTypeDropDownEditor(container, options) {
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    optionLabel: $translate.instant('ADMIN_UNITS_SELECT_MEASUREMENT_UNIT'),
                    dataTextField: 'measurementUnitDescription',
                    dataValueField: 'measurementUnitTypeId',
                    dataSource: {
                        data: $scope.measurementTypes
                    }
                });
        }

        function resourceDropDownEditor(container, options) {
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    optionLabel: $translate.instant('ADMIN_UNITS_SELECT_RESOURCE'),
                    dataTextField: 'resourceDescription',
                    dataValueField: 'resourceId',
                    dataSource: {
                        data: $scope.resources
                    }
                });
        }

        initController();

        function initController() {

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'unitsAdministrationGrid',
                destination: '#units-administration-grid'
            });

            htmlElementService.checkIfElementExists("filter-group")
                .then(function (result) {
                    commonUtility.calculateAdminTabMenuWidth();
                });

            toggleChangesButtons(true)
            var filter = {
                filters: [{
                    field: "IsActive",
                    operator: "contains",
                    value: "1"
                }]
            };
            let requests = {};
            requests.getUnitsByContractorIdWithUnitGroup = unitService.getUnitsByContractorIdWithUnitGroup($rootScope.authData.contractorId, 1, 25, filter);
            requests.getUnitGroupsByContractor = unitService.getUnitGroupsByContractor($rootScope.authData.contractorId);
            requests.getMeasurementUnitTypesWebapi = baseDataService.getMeasurementUnitTypesWebapi()
            requests.getResourcesWebApi = baseDataService.getResourcesWebApi()
            $q.all(requests)
                .then(data => {
                    extendUnits(data.getUnitsByContractorIdWithUnitGroup.items);
                    $scope.units = data.getUnitsByContractorIdWithUnitGroup.items;

                    $scope.unitGroups = data.getUnitGroupsByContractor;
                    $scope.measurementTypes = data.getMeasurementUnitTypesWebapi;
                    $scope.resources = data.getResourcesWebApi;
                    refreshProductsGrid();
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'unitsAdministrationGrid');
                });
        }

        function extendUnits(units) {
            _.forEach(units, function (item) {
                if (item.unitGroup) {
                    item.unitGroupDescription = item.unitGroup.description;
                }

                if (item.measurementUnitType) {
                    item.measurementUnitTypeName = item.measurementUnitType.measurementUnitDescription;
                }

                if (item.resource) {
                    item.resourceName = item.resource.resourceDescription;
                }
            });
        }

        function openUnitWindow(currentFormType, unit) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: currentFormType === formTypes.add ? $translate.instant('ADMIN_CUSTOMERS_ADD_UNIT') : $translate.instant('ADMIN_UNITS_EDIT'),
                    resizable: true,
                    width: 520,
                    visible: false
                },
                templateUrl: 'app/units/units-details-modal-administration-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'unitsDetailsModalAdministrationController',
                resolve: {
                    currentFormType: () => currentFormType,
                    unit: () => unit,
                    unitGroups: () => $scope.unitGroups,
                    measurementTypes: () => $scope.measurementTypes,
                    resources: () => $scope.resources,
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    if (currentFormType == formTypes.add) {
                        $scope.units = $scope.units.concat([result]);
                    } else {
                        $scope.units = $scope.units.map(u => (u.id === result.id ? result : u));
                    }
                    refreshProductsGrid();
                }
            });
        }

        function addNewUnit() {
            openUnitWindow(formTypes.add, null)
        }

        function editUnit(event) {
            var unit = getUnitFromGrid(event)
            openUnitWindow(formTypes.edit, unit)
        }

        function toggleUnitArchive(event) {
            var unit = getUnitFromGrid(event)
            unitService.getUnitBatches(unit.id)
                .then(batches => {
                    var activeBatches = batches.filter(x => x.isActive === true);
                    if (activeBatches && activeBatches.length > 0) {
                        openInformationDialog(`${unit.description} ${$translate.instant('ADMIN_UNITS_ARCHIVE_BATCHES_EXIST_WARNING')}`);
                    }
                    else {
                        confirmDialogService.open(
                            {
                                modalWidth: 400,
                                modalHeight: 150
                            },
                            $translate.instant("ADMIN_UNITS_ARCHIVE_WARNING") + `${"\n"}${unit.externalUnitId} ${unit.description}`,
                            $translate.instant("ADMIN_UNITS_ARCHIVE_WARNING_TITLE"),
                            $translate.instant("G_OK"),
                            $translate.instant("G_CANCEL")
                        ).then(dialogResult => {
                            if (dialogResult === true) {
                                unitService.updateUnitActiveStatus(unit.id, false).then(result => {
                                    openInformationDialog($translate.instant("ADMIN_UNITS_ARCHIVED_MESSAGE"));
                                    refreshProductsGrid();
                                    $scope.$broadcast('refreshProductArchiveGrid');
                                });
                            }
                        });
                    }
                });
        }

        $rootScope.$on('refreshProductsGrid', () => refreshProductsGrid());

        function refreshProductsGrid() {
            if ($scope.unitsAdministrationGridOptions) {
                $scope.unitsAdministrationGridOptions.dataSource.read();
            }
        }

        function openInformationDialog(dialogContent) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    visible: false,
                    width: 400
                },
                templateUrl: 'app/shared/popups/information-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'InformationModalController',
                resolve: {
                    content: () => { return dialogContent }
                }
            });
            windowInstance.result.then(function (response) {
                return response;
            });
        }

        function getUnitFromGrid(event) {
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $('#units-administration-grid')
                .data('kendoGrid')
                .dataItem(row)
                .toJSON();
        }

        function detailInit(e) {
            if (!expandedRowIds.includes(e.data.id)) {
                expandedRowIds.push(e.data.id);
            }

            let requests = {};
            requests.getUnitBatches = unitService.getUnitBatches(e.data.id);
            $q.all(requests)
                .then(data => {
                    $scope.batches = data.getUnitBatches.filter(x => x.isActive === true);
                    $scope.customerUnitAgreements = $scope.batches;
                    const element = e.detailRow.find(`#${e.data.id}_grid`);
                    $scope.unitBatchGridOptions = unitBatchGridOptions($scope.batches);
                    element.kendoGrid($scope.unitBatchGridOptions);

                    // Add unitsBatch
                    $(`#${e.data.id}_add`)
                        .text($translate.instant('G_NEWITEM'))
                        .click(() => addUnitBatch(e.data.id));

                    // Move unitsBatch
                    $(`#${e.data.id}_move`)
                        .text($translate.instant('ADMIN_UNITS_MOVE_UNIT_BATCHES'))
                        .click(() => moveUnitBatches(e.data.id));
                    document.getElementById(`${e.data.id}_move`).style.visibility = 'hidden';

                    // Certificate open
                    $('.k-grid-certificate').click(function (e) {
                        if ($(e.target).data("mediaid")) {
                            viewCertificate($(e.target).data("id"), $(e.target).data("mediaid"))
                        } else {
                            uploadCertificate($(e.target).data("id"))
                        }
                    })
                })
                .catch(error => console.error(error))
        }

        function openUnitBatch(currentFormType, locations, unitId, unitBatch) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_UNITS_ADD_UNIT_BATCH'),
                    resizable: true,
                    width: 520,
                    visible: false
                },
                templateUrl: 'app/units/units-batch-details-modal-administration-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'unitsBatchDetailsModalAdministrationController',
                resolve: {
                    currentFormType: () => currentFormType,
                    locations: () => locations,
                    unitId: () => unitId,
                    unitBatch: () => unitBatch
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    refreshProductsGrid();
                }
            });
        }

        function addUnitBatch(unitId) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'unitsAdministrationGrid',
                destination: '#units-administration-grid'
            });
            let requests = {};
            requests.getLocationsWebApi = locationService.getLocationsWebApi($rootScope.authData.contractorId);
            $q.all(requests)
                .then(data => {
                    var locations = data.getLocationsWebApi;
                    openUnitBatch(formTypes.add, locations, unitId, null)
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'unitsAdministrationGrid');
                });
        }

        function moveUnitBatches(unitId) {
            var selectedRowItems = getSelectedUnitBatch(unitId);
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_UNITS_MOVE_UNIT_BATCHES'),
                    resizable: true,
                    width: 520,
                    visible: false
                },
                templateUrl: 'app/units/move-units-batch-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'moveUnitsBatchModalController',
                resolve: {
                    units: () => $scope.units,
                    unitId: () => unitId,
                    selectedUnitsbatches: () => selectedRowItems
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    initController();
                }
            });
        }

        function getSelectedUnitBatch(id) {
            var grid = $(`#${id}_grid`).data('kendoGrid');
            var rows = grid.select();
            var selectedRowItems = [];
            rows.each(function (index, row) {
                selectedRowItems.push(grid.dataItem(row));
            });
            return selectedRowItems;
        }

        function editUnitBatch(unitId, batchId) {
            var unitBatch = $scope.batches.find(obj => {
                return obj.id === batchId
            });

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'unitsAdministrationGrid',
                destination: '#units-administration-grid'
            });
            let requests = {};
            requests.getLocationsWebApi = locationService.getLocationsWebApi($rootScope.authData.contractorId);
            $q.all(requests)
                .then(data => {
                    var locations = data.getLocationsWebApi;
                    openUnitBatch(formTypes.edit, locations, unitId, unitBatch)
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'unitsAdministrationGrid');
                });
        }

        function viewCertificate(batchId, mediaId) {
            var mediaUrl = mediaService.createMediaUrl(mediaId)
            window.open(mediaUrl, "_blank");
        }

        function uploadCertificate(batchId) {
            var input = document.createElement('input');
            input.type = 'file';

            input.onchange = e => {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'unitsAdministrationGrid',
                    destination: '#units-administration-grid'
                });

                var file = e.target.files[0];
                $scope.$applyAsync(function () {
                    mediaService.asyncEncodeImageFile(file)
                        .then((base64FileContent) => {
                            mediaService.uploadFileWebApi(file.name, base64FileContent).then(function (uploadResult) {
                                if (uploadResult) {
                                    let requests = {};
                                    requests.uploadCertificate = unitService.uploadCertificate(batchId, uploadResult);
                                    $q.all(requests)
                                        .then(data => {
                                            refreshProductsGrid();
                                            $rootScope.$broadcast('hideBusyIndicator', 'unitsAdministrationGrid');
                                        })
                                        .catch(error => console.error(error))
                                } else {
                                    $rootScope.$broadcast('hideBusyIndicator', 'unitsAdministrationGrid');
                                }
                            });
                        });
                });
            }
            input.click();
        }

        function saveChanges() {
            $scope.unitsAdministrationGridOptions.dataSource.sync();
        }

        function cancelChanges() {
            toggleChangesButtons(true)
            refreshProductsGrid();
        }

        function toggleChangesButtons(disabled) {
            var saveChangesButton = $('button.k-button.k-grid-save-changes-units');
            var cancelChangesButton = $('button.k-button.k-grid-cancel-changes-units');
            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function onUnitsGridUpdate(e) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'unitsAdministrationGrid',
                destination: '#units-administration-grid'
            });
            unitService.updateUnit(e.data)
                .then(function (response) {
                    if (response) {
                        toggleChangesButtons(true);
                        e.success();
                        $rootScope.$broadcast('hideBusyIndicator', 'unitsAdministrationGrid');
                    } else {
                        e.error();
                        $rootScope.$broadcast('hideBusyIndicator', 'unitsAdministrationGrid');
                    }
                }, function (error) {
                    e.error(error);
                    $rootScope.$broadcast('hideBusyIndicator', 'unitsAdministrationGrid');
                });

        }
    }
})();
